
// Here you can add other styles
* {
    outline: 0;
    box-sizing: border-box;
  }
  
  *:focus {
    outline: 0;
  }
  
  html, body, #root {
    height: 100%;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    background-color: #F2F2F8 !important;
  }
  
  body, input, button {
    font: 'Roboto', sans-serif;
  }
  
  select {
    option {
      left: 15px;
    }
  }

  // @import "modulos";

.gm-style-cc { display:none; }
a[href^="http://maps.google.com/maps"],
a[href^="https://maps.google.com/maps"],
a[href^="https://www.google.com/maps"] {
display: none !important;
}
// .gm-bundled-control .gmnoprint {
//     display: block;
// }
// .gmnoprint:not(.gm-bundled-control) {
//     display: none;
// }

// Menu Style
// --------------------------------------------------
.AppHeaderStyle {
background-color: rgb(0, 33, 121);
border-bottom: 0px
}

.AppSidebarStyle {
background-color: rgb(0, 33, 121);
border-bottom: 0px
}
// --------------------------------------------------

.boxChange-enter {
opacity: 0;
transform: scale(0.9);
}
.boxChange-enter-active {
opacity: 1;
transform: translateX(0);
transition: opacity 500ms, transform 500ms;
}
.boxChange-exit {
display: none;
}
.boxChange-exit-active {
display: none;
}


.sectionMovimentacao {
.green {
color: #00cd83;
}

.red {
color: #f04156;
}

.pink {
color: #fb549a;
}

.yellow {
color: #ffc100;
}

.blue {
color: #42abe7;
opacity: 0.8;
}

.purple {
color: #696ffd;
}

.grey {
color: #d8d8d8;
}

.grey-dark {
color: #6f6b6b;
}

.hover-purple {
&:hover {
  color: #696ffd;
}
}

.DateRangePickerInput_arrow {
width: 40px;
}

.DateInput_input {
cursor: pointer;
}

.DatePickTheme {
float: left;
display: flex;
align-items: center;
justify-content: center;
}

.circle-icon-green {
background: #00cd83;
color: #fff;
border-radius: 50em;
padding: 0.1rem;
height: 17px;
width: 17px;
}

svg {
font-size: 20px;
}

.navbar-nav {
flex-direction: row;
}

.react-bootstrap-daterangepicker-container{
float: left;
display: flex;
align-items: center;
justify-content: center;
}

.datePickTheme{
float: left;
display: flex;
align-items: center;
justify-content: center;
}

.modulosHeader {
display: flex;
flex-wrap: wrap;
height: 80px;
align-items: center;
justify-content: space-between;

&.mobile {
  display: none;
}

@media (max-width: 600px) {
  display: none;

  &.mobile {
    display: flex;

    select {
      width: 80%;
      background: #ffffff;
      border-radius: 8px;
      border-bottom: 5px solid rgb(0, 33, 121);

      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: rgb(0, 33, 121);
    }

    div {
      width: 20%;
    }
  }
}

@media (max-width: 828px) {
  display: none;

  &.mobile {
    display: flex;

    select {
      width: 80%;
      background: #ffffff;
      border-radius: 8px;
      border-bottom: 5px solid rgb(0, 33, 121);

      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: rgb(0, 33, 121);
    }

    div {
      width: 20%;
    }
  }
}

@media (max-width: 960px) {
  ul.navbar-nav {
    li.nav-item {
      a.nav-link {
        font-size: 17px;
      }
    }
  }

  div {
    .modulosButton {
      font-size: 11px;
    }

    svg {
      font-size: 17px;
    }
  }
}
}

.modulosButton {
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 24px;
align-items: center;
color: #6f6b6b;
background-color: transparent;
border-color: transparent;
margin-left: 20px;
border-radius: 8px;

.ico {
  margin-right: 5px;
}
}

.modulosForm {
display: flex;
background: #fff;
border-radius: 8px;
margin-bottom: 1.5rem;
padding: 0.5rem;
align-items: center;

label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #b5b6ba;
  width: 50%;
  margin: 0;

  svg {
    margin-right: 0.5rem;
  }
}

select,
svg {
  margin-left: 0.5rem;
  border: none;
}

svg {
  width: 10%;
}

input {
  border: none;
}

&.mobile {
  display: none;
}

@media (max-width: 600px) {
  display: none;

  &.mobile {
    display: flex;
    flex-direction: column;

    svg {
      width: 30%;
    }

    div {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    button {
      background: transparent;
      border: unset;
      margin: 0 0.5rem;
      color: #696ffd !important;

      svg {
        width: unset;
        margin: 0;
      }
    }

    select {
      width: 50%;
    }

    div.input-dropdown {
      display: none;

      &.ativo {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        select,
        div {
          width: 100%;
          margin: 0.25rem 0;
        }

        div {
          align-items: center;

          svg {
            width: 10%;
          }
        }
      }
    }
  }
}
}

.rowBox {
height: 200px;
margin-bottom: 1.5rem;

@media (max-width: 600px) {
  height: auto;
  margin-bottom: auto;
}

@media (max-width: 960px) {
  height: auto;
  margin-bottom: 1.5rem;
}

@media (max-width: 1280px) {
  height: auto;
  margin-bottom: 1.5rem;
}
}

.boxAnalise {
background: #ffffff;
box-shadow: 0px 2px 8px rgba(2, 6, 102, 0.15);
border-radius: 8px;
// margin-bottom: 1.5rem;
height: 290px;


.boxAnaliseHeader {
  background: rgb(0, 33, 121);
  border-radius: 8px 8px 0px 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  padding: 1rem;
  align-items: center;
  text-align: start;

  svg {
    margin-right: 1rem;
  }
}

.boxAnaliseFooter {
  background: #fff;
  border-radius: 0px 0px 8px 8px;
  padding: 0.5rem 0.25rem;
  border-top: 1px solid #edeefa;
  align-items: center;
  text-align: center;

  button {
    background: transparent;
    border: unset;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #6f6b6b;
    border-radius: 8px;

    svg {
      margin-right: 1rem;
    }

    &:hover {
      background: #edeefa;
    }
  }

  &.mobile {
    display: none;
  }

  @media (max-width: 600px) {
    &.mobile {
      display: flex;
    }
  }
}

.boxAnaliseBody {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 0;
  text-align: center;
  align-items: center;
  justify-content: center;

  .boxFields {
    padding: 0.25rem 0.75rem;
    width: 50%;
  }

  .boxFieldsUser,
  .boxFieldsUserInfo {
    padding: 0.25rem 0.75rem;
    width: 50%;

    &.boxInfo {
      display: flex;
      flex-direction: column;
      align-items: start;

      span {
        margin: 0.5rem 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #6f6b6b;

        svg {
          margin-right: 0.5rem;
        }
      }
    }

    button {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      color: #6f6b6b;
      background: transparent;
      border: unset;

      svg {
        margin-right: 0.5rem;
      }
    }
  }
}
}

.analiticTable {
td {
  text-align: center;
  vertical-align: middle !important;
  
  &.tdNome {
    padding: 0px 35px 0px 280px;
  }

  &.tdTotal {
    padding: 0px 30px 0px 30px;
  }

}
}

.boxModulos {
background: #ffffff;
box-shadow: 0px 2px 8px rgba(2, 6, 102, 0.15);
border-radius: 8px;
// margin-bottom: 1.5rem;
height: 200px;

@media (max-width: 600px) {
  margin-bottom: 1.5rem;

  &.boxModulos-Perfil {
    height: 180px;

    &.boxExpandido {
      height: 300px !important;

      .boxPerfil {
        height: 200px !important;
      }
    }

    .boxFooter {
      &.mobile {
        display: none;
      }

      @media (max-width: 600px) {
        display: none;

        &.mobile {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

@media (max-width: 960px) {
  margin-bottom: 1.5rem;
}

@media (max-width: 1280px) {
  margin-bottom: 1.5rem;
}

.boxHeader {
  background: rgb(0, 33, 121);
  border-radius: 8px 8px 0px 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  padding: 1rem;
  align-items: center;
  text-align: start;

  svg {
    margin-right: 1rem;
  }
}

.boxFooter {
  background: #fff;
  border-radius: 0px 0px 8px 8px;
  padding: 0.5rem 0.25rem;
  border-top: 1px solid #edeefa;
  align-items: center;
  text-align: center;

  button {
    background: transparent;
    border: unset;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #6f6b6b;
    border-radius: 8px;

    svg {
      margin-right: 1rem;
    }

    &:hover {
      background: #edeefa;
    }
  }

  &.mobile {
    display: none;
  }

  @media (max-width: 600px) {
    &.mobile {
      display: flex;
    }
  }
}



.boxBody {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 0;
  text-align: center;
  align-items: center;
  justify-content: center;

  .boxFields {
    padding: 0.25rem 0.75rem;
    width: 50%;
  }

  .boxFieldsUser,
  .boxFieldsUserInfo {
    padding: 0.25rem 0.75rem;
    width: 50%;

    &.boxInfo {
      display: flex;
      flex-direction: column;
      align-items: start;

      span {
        margin: 0.5rem 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #6f6b6b;

        svg {
          margin-right: 0.5rem;
        }
      }
    }

    .boxContato {
      display: flex;
      align-items: start;
      margin-bottom: 1rem;

      .boxNomeEmail {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-left: 0.75rem;

        .spanNome {
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #292828;
        }

        .spanEmail {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #6f6b6b;
        }
      }
    }

    button {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      color: #6f6b6b;
      background: transparent;
      border: unset;

      svg {
        margin-right: 0.5rem;
      }
    }
  }

  .text-value {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #6f6b6b;
    height: 40px;
    width: 100%;

    svg {
      margin-right: 0.5rem;
    }
  }

  .text-muted {
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 28px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #292828 !important;
    margin-top: 0.5rem;
    height: 40px;
  }

  .small {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin-top: 0.5rem;

    svg {
      margin-right: 0.5rem;
    }
  }

  .smallUser {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
    color: #6f6b6b;
  }

  &.bodyTable {
    padding: 0 !important;
  }

  &.boxPerfil {
    &.mobile {
      display: none;
    }

    @media (max-width: 600px) {
      display: none;

      &.mobile {
        display: flex;
        height: 80px;
      }
    }
  }

  .boxFieldsUserInfo {
    display: flex;
    flex-direction: column;
    width: 100%;

    div.othersInfos {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 1.5rem;

      svg {
        margin-right: 1.5rem;
      }

      button {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        color: #6f6b6b;
        text-align: start;
        padding-left: 0;
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #6f6b6b;
        margin: 0.25rem 0;
        text-align: start;
      }
    }
  }
}

// Box Perfil
.boxPerfilBody {
  display: flex;
  flex-direction: row;
  /* padding: 0.75rem 0; */
  text-align: center;
  /* align-items: center; */
  justify-content: center;
  height: 75%;

  .boxPerfilFoto {
    // padding: 0.25rem 0.75rem;
    width: 15%;
    // background-color: #fb549a;
  }

  .boxPerfilFields {
    // padding: 0.25rem 0.75rem;
    width: 55%;
    // background-color: aquamarine;
  }

  .boxMensagem {
    background: #fff;
    border-radius: 0px 0px 8px 8px;
    padding: 0.5rem 0.25rem;
    // border-top: 1px solid #edeefa;
    align-items: center;
    text-align: center;
    width: 50%;
    margin: 4% 0 0 25%;

    button {
      background: transparent;
      border: unset;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      text-align: center;
      color: #6f6b6b;
      border-radius: 8px;

      svg {
        margin-right: 1rem;
      }

      &:hover {
        background: #edeefa;
      }
    }

    &.mobile {
      display: none;
    }

    @media (max-width: 600px) {
      &.mobile {
        display: flex;
      }
    }
  }

  .boxPerfilCargoFields {
    padding: 25px 0px 0px 15px;
    width: 30%;
    // background-color: bisque;
  }

  .boxPerfilFieldsUser,
  .boxPerfilFieldsUserInfo {
    padding: 0.25rem 0.75rem;
    width: 50%;

    &.boxPerfilInfo {
      display: flex;
      flex-direction: column;
      align-items: start;

      span {
        margin: 0.5rem 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #6f6b6b;

        svg {
          margin-right: 0.5rem;
        }
      }
    }

    .boxPerfilContato {
      display: flex;
      align-items: start;
      margin-bottom: 1rem;

      .boxPerfilNomeEmail {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-left: 0.75rem;

        .spanNome {
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #292828;
        }

        .spanEmail {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #6f6b6b;
        }
      }
    }

    button {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      color: #6f6b6b;
      background: transparent;
      border: unset;

      svg {
        margin-right: 0.5rem;
      }
    }
  }

  .img-perfil {
    height: 75px;
    width: 75px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 50%;
    box-shadow: 0px 2px 8px rgba(2, 6, 102, 0.15);

    @media (max-width: 1012px) {
      height: 75px;
      width: 75px;
      margin-top: 5px !important;
    }

    @media (max-width: 990px) {
      height: 45px !important;
      width: 45px !important;
      margin-top: 5px !important;
    }

    @media (max-width: 890px) {
      height: 30px !important;
      width: 30px !important;
      margin-top: 10px !important;
    }

  }

  .DivRodape{
    position: absolute;
    top: auto;
    height: 35px;
    line-height: 35px;
    text-align: center;
    width: 100%;
    background-color:#004085;
  }
  .Div1{
    position: relative;
    margin: 10px 10px 0px 10px;
  }
  .Div2{
      position:relative;
      border-width:2px;
      float: left;
      background-color: red;
  }
  .Div3{
    position:relative;
    border-width:2px;
    float: left;
    background-color: yellow;
  }
  

  .text-nome {
    text-align: initial;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    align-items: center;
    text-transform: capitalize;
    color: #292828 !important;
    margin-left: 15px;
    margin-top: 25px;
    height: 30px;

    @media (max-width: 1012px) {
      font-weight: 250!important;
      font-size: 15px!important;
      line-height: 15px !important;
    }

    @media (max-width: 990px) {
      font-weight: 250!important;
      font-size: 15px!important;
      line-height: 15px !important;
    }

  }
  
  .text-email {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: initial;
    color: #6f6b6b;
    height: 40px;
    margin-left: 15px;

    svg {
      margin-right: 0.5rem;
    }

    @media (max-width: 1012px) {
      height: 22px !important;
    }

    @media (max-width: 990px) {
      height: 22px !important;
    }

  }

  .text-mensagem {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    text-align: left;
    text-align: initial;
    color: #6f6b6b;
    width: 100%;
    // height: 40px;

    button {
      background: transparent;
      border: unset;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      text-align: center;
      color: #6f6b6b;
      border-radius: 8px;
    }

    svg {
      margin-right: 0.5rem;
    }
  }

  .text-value {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: initial;
    color: #6f6b6b;
    height: 40px;
    width: 100%;

    svg {
      margin-right: 0.5rem;
    }

    @media (max-width: 890px) {
      font-size: 7px;
      line-height: 10px;
    }

  }

  .text-muted {
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 28px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #292828 !important;
    margin-top: 0.5rem;
    height: 40px;
  }

  .small {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin-top: 0.5rem;

    svg {
      margin-right: 0.5rem;
    }
  }

  .smallUser {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
    color: #6f6b6b;
  }

  &.bodyTable {
    padding: 0 !important;
  }

  &.boxPerfil {
    &.mobile {
      display: none;
    }

    @media (max-width: 600px) {
      display: none;

      &.mobile {
        display: flex;
        height: 80px;
      }
    }
  }

  .boxPerfilFieldsUserInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    

    div.othersInfos {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 1.5rem;

      svg {
        margin-right: 1.5rem;
      }

      button {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        color: #6f6b6b;
        text-align: start;
        padding-left: 0;
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #6f6b6b;
        margin: 0.25rem 0;
        text-align: start;
      }
    }
  }
}

.tableExpanse{
  display: block;
  height: 500px !important;
  overflow-y: scroll;
  @media (max-width: 1012px) {
    height: 500px !important;
  }

  @media (max-width: 990px) {
    height: none;
    margin: 0px 0px 15px 0px;
  }
}

.table-responsive {
  overflow-y: scroll;
  height: 100px;
}

.boxTable {
  height: 100px;
  margin: 0;

  th {
    border-bottom: 1px solid #edeefa;
    border-top: unset;
    text-align: start;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6f6b6b;
    // padding: 10px 50px 10px 0.75rem;

    @media (max-width: 1012px) {
      padding: 0.5rem !important;
    }

    @media (max-width: 990px) {
      padding: 0.75rem !important;
    }
  }

  td {
    border: unset;
    padding: 0 0.15rem;

    &.tdHour {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 32px;
      color: #6f6b6b;
    }

    &.tdColaborador {
      display: inline-block;
      width: 10em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 32px;
      color: #6f6b6b;
      text-align: start;
    }

    &.tdAtualizacao {
      display: inline-block;
      width: 12em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 32px;
      color: #6f6b6b;
      text-align: start;
    }
  }
}

.pinSize {
  background-size: contain;
  background-position: top;
  height: 25px;
}

.floating-map-menu {
  z-index: 100;
  position: absolute;
  /* top: 11px; */
  bottom: 10px;
  /* right: 12px; */
  left: 10px;
  background: #ffffff;
  color: #000;
  align-items: center;
  text-align: start;
  box-shadow: 0px 2px 4px rgba(2, 6, 102, 0.15);
  border-radius: 50%;
  font-size: 10px;
  width: 50px;
  height: 50px;

  .row {
    display: initial;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}


.floating-map-menu img{
  width:100%;
  height:100%;
  position:absolute;
  z-index:1;
  }

.floating-map-menu a, 
.floating-map-menu h3 {
  font-size: 0.9em;
  display: initial;
  margin: 0 0.5em;
  color: white;

  @media (max-width: 1012px) {
    display: block !important;
  }

  @media (max-width: 990px) {
    display: block !important;
  }
  
}

.floating-menu {
  z-index: 100;
  position: absolute;
  top: 11px;
  /* bottom: 10px; */
  // right: 12px;
  left: 10px;
  background: #ffffff;
  color: #000;
  align-items: center;
  text-align: start;
  box-shadow: 0px 2px 4px rgba(2, 6, 102, 0.15);
  /* border-radius: 8px; */
  font-size: 10px;

  .row {
    display: initial;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  @media (max-width: 1012px) {
    width: 100% !important;
    background: #ffffff !important;
    box-shadow: 0px 2px 8px rgba(2, 6, 102, 0.15) !important;
    padding: 1rem;
    .row {
      display: flex;
    }
  }

  @media (max-width: 990px) {
    width: 93% !important;
    background: #ffffff !important;
    box-shadow: 0px 2px 8px rgba(2, 6, 102, 0.15) !important;
    padding: 1rem;
    .row {
      display: flex;
    }
  }
}

.floating-menu a, 
.floating-menu h3 {
  font-size: 0.9em;
  display: initial;
  margin: 0 0.5em;
  color: white;

  @media (max-width: 1012px) {
    display: block !important;
  }

  @media (max-width: 990px) {
    display: block !important;
  }
  
}

.boxMap {
  // border: 1px solid #999;
  height: 555px;
  width: 100%;
  background: #e6e6e6;

  @media (max-width: 990px) {
    height: none;
  }

  // @media (max-width: 1012px) {
  //   height: 403px;
  // }
}



.colMap {
  padding: 0;
}

.iconRegistros {
  position: absolute;
  top: 8px;
  right: 15px;

  color: #6f6b6b;
  float: right;
  cursor: pointer;
  background: transparent;
  border: unset;
  padding: 0;

  &:hover {
    color: #423e3e;
  }

  @media (max-width: 990px) {
    display: none;
  }
}
.iconColaboradores {
  margin-right: -15px;
  float: right;
  cursor: pointer;
  background: transparent;
  border: unset;
  padding: 0;

  &:hover {
    color: #423e3e;
  }

  @media (max-width: 990px) {
    display: none;
  }
}

&.boxExpandir {
  max-height: 400px;
  height: unset;
  z-index: 99;
  position: relative;
  // width: 90%;

  @media (max-width: 768px) {
    position: unset;
    z-index: unset;
    width: unset;
  }

  // @media (max-width: 600px) {
  //   position: unset;
  //   z-index: unset;
  // }

  .table-responsive {
    height: unset;
    max-height: 200px;
  }

  .boxTable {
    height: unset;
    max-height: 200px;
  }
}
}

.card {
height: auto !important;
padding: 0 !important;

@media (max-width: 960px) {
  padding: 0 !important;
}

@media (max-width: 1280px) {
  padding: 0 !important;
}

.card-header {
  text-align: start !important;
}

.card-body {
  padding: 0 !important;

  @media (max-width: 990px) {
    flex-direction: column;
  }
}

.tableRegistros {
  // height: 300px !important;

  &.ajusteHeight {
    height: 40px !important;
    margin-bottom: 0px;
    display: table;
  }

  tr {
    align-items: center;
  }

  th {
    border-bottom: 1px solid #edeefa;
    border-top: unset;
    text-align: start;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6f6b6b;

    @media (max-width: 1012px) {
      padding: 0.5rem !important;
    }

    @media (max-width: 990px) {
      padding: 0.75rem !important;
    }
  }

  tbody {
    overflow-y: scroll;
    
    tr:hover {
      background: #edeefa;
      cursor: pointer;

      td {
        &.firstTd {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &.lastTd {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    td {
      &.firstTd {
        width: 180px;
      }

      &.lastTd {
        width: 180px;
      }
    }
    

    td {
      border: unset;
      
      span.registroText {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        color: #292828;
        display: inline-block;
        width: 10em;
        overflow: hidden;
        /* white-space: nowrap; */
        text-overflow: ellipsis;
        text-align: start;
      }

      span.registroSmall {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #636363;
        margin-bottom: 0.25rem;
      }
    }
  }
}

.colTimeline {
  padding: 0 !important;
}

.divTitle {
  align-items: center;
  padding: 1rem 0.25rem 0.25rem 0.25rem;
  margin: 0 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #edeefa;

  span.spanTh {
    border-top: unset;
    text-align: start;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6f6b6b;
    width: 38%;
    padding-bottom: 0.5rem;
    text-align: start;
  }

  .w62 {
    width: 62% !important;
  }
}

.timeline--wrapper {
  padding: 0 !important;
  width: 100%;
}

.timelineRegistros {
  text-align: start;
  margin: 0 !important;
  height: 336px !important;
  width: 100%;
  overflow-y: scroll;

  &::before {
    border: 1.5px solid;

    @media (max-width: 600px) {
      left: calc(33% + 6px) !important;
    }
  }

  div.title {
    @media (max-width: 600px) {
      width: 34% !important;
    }

    &::before {
      background-color: #958f8f;
      right: -11px;
      width: 3px;
      height: 3px;

      @media (max-width: 600px) {
        right: -10px !important;
        left: unset !important;
      }
    }

    span.timeline-item-date {
      background: transparent;

      time.timeline-item-dateinner {
        background: transparent;
        padding-top: 15px;
      }
    }
  }

  div.body {
    @media (max-width: 600px) {
      width: 66% !important;
      margin: 0 0 3em !important;
    }
  }

  span.registroText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #292828;
  }

  span.registroSmall {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #b5b6ba;
    margin-bottom: 0.25rem;
  }
}

.tableRegistrosFullScreen {
  max-height: 300px !important;

  svg {
    margin-right: 0.5rem;
  }

  tr {
    align-items: center;

    &.rowDate {
      border-top: 1px solid #edeefa;
      border-bottom: 1px solid #edeefa;

      td {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #b5b6ba;
      }
    }
  }

  th {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6f6b6b;
    border: unset;
    text-align: start;
  }

  tbody {
    overflow-y: scroll;
    

    td {
      border: unset;
      text-align: start;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 13px;
      align-items: center;
      color: #292828;

      &.boxTd {
        padding: 0.25rem;
      }

      .boxDiv {
        border: 2px solid #f2f2f8;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 0.5rem 0;
        height: auto;
        width: auto;
        text-align: center;
        align-items: center;
      }
    }
  }
}
}
}

  
  // Menu lateral
  .sidebar {
    background: #ffffff;
    color: #edeefa;
  
    .nav {
      align-items: center;
      width: 220px;
    }
  
    .sidebar-nav {
      align-items: center;
      width: 220px;
    }
  
    .nav-title {
      color: #3e44c8;
      border-bottom: 1px solid #edeefa;
      width: 80%;
      padding-left: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 144.14%;
      display: flex;
      align-items: center;
    }
  
    .nav-item {
      width: 100%;
      padding: 0.5rem 0;
      border-bottom: 0px solid #edeefa;
    }
  
    // Curvas
    // .nav-link {
    //   color: #edeefa;
    //   padding: 0.75rem 1.3rem;
    //   font-style: normal;
    //   font-weight: 500;
    //   font-size: 17px;
    //   line-height: 16px;
    //   display: flex;
    //   align-items: center;
    //   text-transform: capitalize;
  
    //   &:hover {
    //     color: #000;
    //     background: #f2f2f8;
    //     width:108%;
    //   }
  
    //   &.active {
    //     background: #f2f2f8;
    //     background-color:#f2f2f8;
    //     color: #000;
    //     width:108%;
    //     display:inline-block;
    //     height:90px;
    //     top:0;
    //     left:0;
    //     padding: 36px 0px 0px 25px;
    //   }
  
    //   &.active:before {
    //     content: "";
    //     position: absolute;
    //     top: 5px;
    //     right: 0px;
    //     height: 25px;
    //     width: 110%;
    //     background-color: rgb(0, 33, 121);
    //     border-bottom-right-radius: 80px;
    //     }
  
    //     &.active:after {
    //       content: "";
    //       position: absolute;
    //       top: 75px;
    //       right: 0px;
    //       height: 25px;
    //       width: 108%;
    //       background-color: rgb(0, 33, 121);
    //       border-top-right-radius: 80px;
    //     }
  
    .nav-link {
      color: #edeefa;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 16px;
      display:inline-block;
      width: 90%;
      height: 65px;
      margin-left: 10px;
      padding: 25px 0px 0px 0px;
  
  
      &:hover {
        background: #f2f2f8;
        background-color:#f2f2f8;
        color: #000;
        width: 90%;
        display:inline-block;
        height: 65px;
        margin-left: 10px;
        border-radius: 20px 20px 20px 20px;
        padding: 25px 0px 0px 0px;
      }
  
  
      &.active {
        background: #f2f2f8;
        background-color:#f2f2f8;
        color: #000;
        width: 90%;
        display:inline-block;
        height: 65px;
        margin-left: 10px;
        border-radius: 20px 20px 20px 20px;
        padding: 25px 0px 0px 0px;
      }
      
      .nav-icon {
        position: absolute;
      }
  
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  
    // .nav-icon {
    svg {
      // color: #6f6b6b !important;
      margin: 0 0.5rem 0 0;
      font-size: 20px;
    }
  
  }
  // Menu lateral
  
  @media (max-width: 991.98px) {
    .app-header .navbar-brand {
      position: unset;
      top: 0;
      left: 0;
      margin-left: 0;
    }
  }
  
  button:focus {
    border: unset !important;
    outline: unset !important;
    box-shadow: unset !important;
  }
  
  .app-footer {
    background: transparent;
    // margin: 0 30px 0 230px !important;
    border-top: 1px solid #c9c9ce;
    margin: 0 auto 0 auto !important;
    padding: 1rem;
    width: 100%;
    bottom: 0;
  }
  
  .logoFooter {
    width: 50px;
    mix-blend-mode: luminosity;
  }
  
  .spanFooter {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #958f8f;
  }
  
  .limiteEmail{
    display: block;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  // .app {
  //   background: #e5e5e5;
  // }
  
  .app-header .navbar-brand {
    width: 100px !important;
  }
  
  .app-header .nav-item {
    display: block !important;
  }
  
  @media (min-width: 992px) {
    .sidebar-minimized {
      .sidebar {
        .nav {
          align-items: flex-start;
        }
  
        .nav-item {
          width: 28px;
          overflow: hidden;
          margin: 0 1rem;
  
          &:hover {
            .nav-link{
              background: #edeefa !important;
              border-radius: 8px;
            }
          }
        }
  
        .nav-link {
          position: relative;
          padding-left: 0.25rem;
          margin: 0;
          white-space: nowrap;
          border-left: 0;
  
          &:hover {
            background: #edeefa !important;
            border-radius: 8px;
          }
        }
  
        .item-mov {
          .nav-link:hover {
            color: rgb(0, 33, 121);
          }
      
          .nav-link {
            &.active {
              background: rgb(0, 33, 121) !important;
              border-radius: 8px;
              color: #fff;
            }
          }
        }
      
        .item-prod {
          .nav-link:hover {
            color: #ff9600;
      
          }
      
          .nav-link {
            &.active {
              background: #ff9600 !important;
              border-radius: 8px;
              color: #fff;
            }
          }
        }
      
        .item-seg {
          .nav-link:hover {
            color: #4ac89b;
          }
      
          .nav-link {
            &.active {
              background: #4ac89b !important;
              border-radius: 8px;
              color: #fff;
            }
          }
        }
      
        .item-analises {
          .nav-link:hover {
            color: #6f6b6b;
          }
      
          .nav-link {
            &.active {
              background: #6f6b6b !important;
              border-radius: 8px;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .analisesHeader {
  display: flex;
  flex-wrap: wrap;
  height: 80px;
  align-items: center;
  justify-content: space-between;
}

.analisesItem {
  float: left;
  margin-right: 50px;

  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #3e44c8;

  svg {
    margin-right: 5px;
  }

  @media (max-width: 600px) {
    margin-right: 37px;
  }
}

.analisesButton {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  color: #6f6b6b;
  background-color: transparent;
  border-color: transparent;
  margin-left: 20px;
  border-radius: 8px;

  .ico {
    margin-right: 5px;
  }

  @media (max-width: 600px) {
    &.download {
      display: none;
    }
  }
}


.analisesForm {
  display: flex;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;

  &.mobile {
    display: none;
  }

  @media (max-width: 600px) {
    display: none;

    &.mobile {
      display: flex;
      flex-direction: column;

      svg {
        width: 30%;
      }

      div.filterDiv {
        display: flex;
        flex-direction: row;
        width: 100%;

        button {
          background: transparent;
          border: unset;
          margin: 0 1.5rem;
          color: #696ffd !important;

          svg {
            width: unset;
            margin: 0;
          }

          &:focus {
            background: transparent !important;
          }
        }

        select {
          width: 50%;
        }

        label {
          width: 35%;
        }
      }

      div.input-dropdown {
        display: none;

        &.ativo {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          select,
          div {
            width: 100%;
            margin: 0.25rem 0;
          }

          div {
            align-items: center;

            svg {
              width: 10%;
            }
          }
        }
      }
    }
  }

  div {
    width: 40%;
    display: flex;
    align-items: center;

    &.filterDiv {
      width: 60%;
    }
  }

  button {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 13px;
    align-items: center;
    color: #958f8f;
    background-color: transparent;
    border-color: transparent;
    margin-left: 20px;
    border-radius: 8px;
    padding: 1rem 0.5rem;

    &:hover {
      background: #6f6b6b;
      color: #ffffff;
    }
  }

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #b5b6ba;
    width: 15%;
    margin: 0;

    svg {
      margin-right: 0.5rem;
    }
  }

  select,
  svg {
    margin-left: 0.5rem;
    border: none;
  }

  svg {
    width: 10%;
  }

  input {
    border: none;
    width: auto;
  }
}



.boxAnalises {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(2, 6, 102, 0.15);
  border-radius: 8px;
  margin-bottom: 1.5rem;
  height: 200px;

  .boxHeader {
    border-radius: 8px 8px 0px 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    padding: 1rem;
    align-items: center;
    text-align: start;
    background: #3e44c8;

    svg {
      margin-right: 1rem;
    }

    &.boxMov {
      background: #1b6eec !important;
    }

    &.boxProd {
      background: #ff9600 !important;
    }

    &.boxSeg {
      background: #4ac89b !important;
    }
  }

  // .boxFooter {
  //   background: #fff;
  //   border-radius: 0px 0px 8px 8px;
  //   padding: 0.5rem 0.25rem;
  //   border-top: 1px solid #edeefa;
  //   align-items: center;
  //   text-align: center;

  //   button {
  //     background: transparent;
  //     border: unset;
  //     font-style: normal;
  //     font-weight: 500;
  //     font-size: 14px;
  //     line-height: 20px;
  //     align-items: center;
  //     text-align: center;
  //     color: #6f6b6b;
  //     border-radius: 8px;

  //     svg {
  //       margin-right: 1rem;
  //     }

  //     &:hover {
  //       background: #edeefa;
  //     }
  //   }
  // }

  .boxBody {
    display: flex;
    flex-direction: row;
    padding: 0.75rem 0;
    text-align: center;
    align-items: center;
    justify-content: center;

    .boxFields {
      padding: 0.25rem 0.75rem;
      width: 100%;
    }

    .boxFieldsUser {
      padding: 0.25rem 0.75rem;
      width: 50%;

      &.boxInfo {
        display: flex;
        flex-direction: column;
        align-items: start;

        span {
          margin: 0.5rem 0;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #6f6b6b;

          svg {
            margin-right: 0.5rem;
          }
        }
      }

      .boxContato {
        display: flex;
        align-items: start;
        margin-bottom: 1rem;

        .boxNomeEmail {
          display: flex;
          flex-direction: column;
          align-items: start;
          margin-left: 0.75rem;

          .spanNome {
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #292828;
          }

          .spanEmail {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #6f6b6b;
          }
        }
      }

      button {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        color: #6f6b6b;
        background: transparent;
        border: unset;

        svg {
          margin-right: 0.5rem;
        }
      }
    }

    .text-value {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #6f6b6b;
      height: 40px;
      width: 100%;

      svg {
        margin-right: 0.5rem;
      }
    }

    .text-muted {
      font-style: normal;
      font-weight: 900;
      font-size: 28px;
      line-height: 28px;
      align-items: center;
      text-align: center;
      color: #292828 !important;
      margin-top: 0.5rem;
      height: 40px;
    }

    .small {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin-top: 0.5rem;

      svg {
        margin-right: 0.5rem;
      }

      .lineThrough {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-decoration-line: line-through;
        color: #b5b6ba;
        margin-right: 0.5rem;
      }
    }

    .smallUser {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin-top: -0.5rem;
      margin-bottom: 0.5rem;
      color: #6f6b6b;
    }

    &.bodyTable {
      padding: 0 !important;
    }
  }
}


#imgBotao{
  filter: none;
  -webkit-filter: grayscale(0);
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  transition: all 0.8s linear;
}

#imgBotao:hover{
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  transition: all 0.8s linear;
  // filter: url("data:image/svg+xml;utf8,<svg xmlns="\'http://www.w3.org/2000/svg\'"><filter id="\'grayscale\'"><feColorMatrix type="\'matrix\'" values="\'0.3333" 0.3333="" 0="" 1="" 0\'=""></feColorMatrix></filter></svg>#grayscale"); /* Firefox 3.5+ */
  filter: drop-shadow(2px 4px 6px black); /* IE6-9 */
  // -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}

.progressBarLocalNaoProdutivoCustom {
  position: relative;
  display: flex;
  height: 1.5rem;
  overflow: hidden;
  font-size: 0.80rem;
  background-color: #f0f3f5;
  border-radius: 0.25rem
}

.progressBarLocalNaoProdutivoCustom-bar {
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: center;
  white-space: nowrap;
  height: 1.5rem;
  background-color: #017ffa;
  transition: width 0.6s ease;
}

.progressBarLocalNaoProdutivoCustom span {
  position: absolute;
  top: 0;
  z-index: 2;
  text-align: center;
  width: 100%;
  color: black;
}

.progressBarTempoLocalProdutivoCustom {
  position: relative;
  display: flex;
  height: 1.5rem;
  overflow: hidden;
  font-size: 0.80rem;
  background-color: #f0f3f5;
  border-radius: 0.25rem
}

.progressBarTempoLocalProdutivoCustom-bar {
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: center;
  white-space: nowrap;
  height: 1.5rem;
  background-color: #017ffa;
  transition: width 0.6s ease;
}

.progressBarTempoLocalProdutivoCustom span {
  position: absolute;
  top: 0;
  z-index: 2;
  text-align: center;
  width: 100%;
  color: black;
}

.progressBarTempoPostoTrabalhoCustom {
  position: relative;
  display: flex;
  height: 1.5rem;
  overflow: hidden;
  font-size: 0.80rem;
  background-color: #f0f3f5;
  border-radius: 0.25rem
}

.progressBarTempoPostoTrabalhoCustom-bar {
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: center;
  white-space: nowrap;
  height: 1.5rem;
  background-color: #017ffa;
  transition: width 0.6s ease;
}

.progressBarTempoPostoTrabalhoCustom span {
  position: absolute;
  top: 0;
  z-index: 2;
  text-align: center;
  width: 100%;
  color: black;
}

.progressBarDeslocamentoCustom {
  position: relative;
  display: flex;
  height: 1.5rem;
  overflow: hidden;
  font-size: 0.80rem;
  background-color: #f0f3f5;
  border-radius: 0.25rem
}

.progressBarDeslocamentoCustom-bar {
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: center;
  white-space: nowrap;
  height: 1.5rem;
  background-color: #017ffa;
  transition: width 0.6s ease;
}

.progressBarDeslocamentoCustom span {
  position: absolute;
  top: 0;
  z-index: 2;
  text-align: center;
  width: 100%;
  color: black;
}

#imgColorida{
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  transition: all 0.8s linear;
  // filter: url("data:image/svg+xml;utf8,<svg xmlns="\'http://www.w3.org/2000/svg\'"><filter id="\'grayscale\'"><feColorMatrix type="\'matrix\'" values="\'0.3333" 0.3333="" 0="" 1="" 0\'=""></feColorMatrix></filter></svg>#grayscale"); /* Firefox 3.5+ */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%);
   
}

#imgColorida:hover{
  filter: none;
  -webkit-filter: grayscale(0);
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  transition: all 0.8s linear;
}

#imgCinza{
  filter: none;
  -webkit-filter: grayscale(0);
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  transition: all 0.8s linear;
}

#imgCinza:hover{
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  transition: all 0.8s linear;
  // filter: url("data:image/svg+xml;utf8,<svg xmlns="\'http://www.w3.org/2000/svg\'"><filter id="\'grayscale\'"><feColorMatrix type="\'matrix\'" values="\'0.3333" 0.3333="" 0="" 1="" 0\'=""></feColorMatrix></filter></svg>#grayscale"); /* Firefox 3.5+ */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}

/* Tour */
.mask {
  color: #454e5c;
  opacity: 0.8;
}

.veiculoTempoDeTrabalhoTour {
  width: 100%;
}

.veiculoTempoDeProdutivoTour {
  width: 100%;
}

.veiculoTempoDeDeslocamentoTour {
  width: 100%;
}

.veiculoTempoNaoProdutivoTour {
  // width: 100%;
}

// Layout do componente Tempo de Trabalho //
.veiculoTempoDeTrabalhoFundoCirculo {
  border-radius: 50%;
  width: 85%;
  height: 35%;
  margin: 120px 5%;
  position: absolute;
  z-index: 99;
  // background: aquamarine;
  background-color: rgb(255, 255, 255);
}

.veiculoTempoDeTrabalhoConteudoCirculo {
  align-items: center;
  // background: bisque;
  height: 100%;
  width: 100%;
  margin: 5px 0px 0px 0px;
  position: absolute;
  z-index: 99;
}

.veiculoTempoDeTrabalhoInfo {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: rgb(255, 255, 255);
  padding: 1rem;
  align-items: center;
  text-align: start;
  background: #a0a0a0;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 150px;
  right: 5px;
  z-index: 99
}

.veiculoTempoDeTrabalhoInfoButton {
  align-items: center;
  width: 15px;
  height: 15px;
  position: absolute;
  top: -1px;
  right: 13px;
  background-color: #ffffff00;
  border: none;
  z-index: 99;
  color: #ffffff
}

.veiculoTempoDeTrabalhoFundoTopo {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  padding: 1rem;
  align-items: center;
  text-align: start;
  height: 170px;
  background: rgb(0, 33, 121);
  clip-path: polygon(0 0, 100% 17%, 100% 100%, 0 100%);
  margin: -30px 0px -40px 0px;
}

.veiculoTempoDeTrabalhoBoxConteudo {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(2, 6, 102, 0.15);
  border-radius: 8px;
  margin-bottom: 1.5rem;
  height: 550px
}

// Conteudo interno
.veiculoTempoDeTrabalhoBoxConteudoTitulo {
  font-size: 16px;
  line-height: 16px;
  color: black;
  align-items: center;
  text-align: center;
  width: 95%;
  height: 25px;
  position: absolute;
  top: 320px;
  z-index: 99
}

// Borda em contorno cortado na metade
.veiculoTempoDeTrabalhoBoxConteudoPorcentagem {
  width: 80%;
  border: solid;
  border-color: blue;
  border-radius: 0px 50px 50px 0px;
  height: 75px;
  position: absolute;
  top: 390px;
  z-index: 99;
  border-width: 3px 3px 3px 0px;
  left: 45px
}

.veiculoTempoDeTrabalhoBoxConteudoPorcentagemValor {
  font-size: 22px;
  font-weight: bold;
  font-family: inherit;
  color: rgb(0, 33, 121);
  position: absolute;
  right: 10px;
  top: 18px
}

.veiculoTempoDeTrabalhoBoxConteudoDescricaoPrimeiro {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position: absolute;
  top: -35px;
  left: -5px
}

.veiculoTempoDeTrabalhoBoxConteudoDescricaoPrimeiroValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: -15px;
  height: 25px;
  width: 160px;
  left: -5px;
  background-color: black;
  z-index: 99
}

.veiculoTempoDeTrabalhoBoxConteudoDescricaoSegundo {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position:absolute;
  top: 35px;
  font-weight: bold;
  left: -5px
}

.veiculoTempoDeTrabalhoBoxConteudoDescricaoSegundoValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: 55px;  
  height: 25px;
  width: 120px;
  left: -5px;
  background-color: rgb(0, 33, 121);
  z-index: 99
}

// Box rodapé
.veiculoTempoDeTrabalhoBoxConteudoRodape {
  width: 100%;
  border-color: blue;
  text-align: center;
  left: -30px;
  width: 120%;
  height: 140px;
  position:absolute;
  top: 120px
}

// Conteudo do box 1
.veiculoTempoDeTrabalhoBoxConteudoRodapeBox1 {
  float: left;
  width: 34%;
  height: 70%;
  position: relative;
  display: inline-table;
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 33, 121)
}

.veiculoTempoDeTrabalhoBoxConteudoRodapeBox1Titulo {
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeTrabalhoBoxConteudoRodapeBox1Valor {
  font-size: 23px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeTrabalhoBoxConteudoRodapeBox1Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center; 
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 2
.veiculoTempoDeTrabalhoBoxConteudoRodapeBox2 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table;
  // "background-color": "red",
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 33, 121)
}

.veiculoTempoDeTrabalhoBoxConteudoRodapeBox2Titulo {
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeTrabalhoBoxConteudoRodapeBox2Valor {
  font-size: 23px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeTrabalhoBoxConteudoRodapeBox2Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;  
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 3
.veiculoTempoDeTrabalhoBoxConteudoRodapeBox3 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table
  // "background-color": "green"
}

.veiculoTempoDeTrabalhoBoxConteudoRodapeBox3Titulo {
  font-size: 14px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position:absolute;
  color:rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeTrabalhoBoxConteudoRodapeBox3Valor {
  font-size: 23px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeTrabalhoBoxConteudoRodapeBox3Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Layout do componente Tempo de Trabalho //
// Layout do componente Tempo Produtivo //
.veiculoTempoDeProdutivoFundoCirculo {
  border-radius: 50%;
  width: 85%;
  height: 35%;
  margin: 90px 5%;
  position: absolute;
  z-index: 99;
  // background: aquamarine;
  background-color: rgb(255, 255, 255);
}

.veiculoTempoDeProdutivoConteudoCirculo {
  align-items: center;
  // background: bisque;
  height: 100%;
  width: 100%;
  margin: 5px 0px 0px 0px;
  position: absolute;
  z-index: 99;
}

.veiculoTempoDeProdutivoInfo {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: rgb(255, 255, 255);
  padding: 1rem;
  align-items: center;
  text-align: start;
  background: #a0a0a0;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 150px;
  right: 5px;
  z-index: 99
}

.veiculoTempoDeProdutivoInfoButton {
  align-items: center;
  width: 15px;
  height: 15px;
  position: absolute;
  top: -1px;
  right: 13px;
  background-color: #ffffff00;
  border: none;
  z-index: 99;
  color: #ffffff
}

.veiculosTempoDeProdutivoFundoTopo {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  padding: 1rem;
  align-items: center;
  text-align: start;
  height: 140px;
  background: rgb(0, 33, 121);
  clip-path: polygon(0 0, 100% 17%, 100% 100%, 0 100%);
  margin: 0px 0px -40px 0px;
}

.veiculoTempoDeProdutivoBoxConteudo {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(2, 6, 102, 0.15);
  border-radius: 8px;
  margin-bottom: 1.5rem;
  height: 550px
}

// Conteudo interno
.veiculoTempoDeProdutivoBoxConteudoTitulo {
  font-size: 16px;
  line-height: 16px;
  color: #64813d;
  align-items: center;
  text-align: center;
  width: 95%;
  height: 25px;
  position: absolute;
  top: 320px;
  z-index: 99
}

// Borda em contorno cortado na metade
.veiculoTempoDeProdutivoBoxConteudoPorcentagem {
  width: 80%;
  border: solid;
  border-color: blue;
  border-radius: 0px 50px 50px 0px;
  height: 75px;
  position: absolute;
  top: 390px;
  z-index: 99;
  border-width: 3px 3px 3px 0px;
  left: 45px
}

.veiculoTempoDeProdutivoBoxConteudoPorcentagemValor {
  font-size: 22px;
  font-weight: bold;
  font-family: inherit;
  color: #64813d;
  position: absolute;
  right: 10px;
  top: 18px
}

.veiculoTempoDeProdutivoBoxConteudoDescricaoPrimeiro {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position: absolute;
  top: -35px;
  left: -5px
}

.veiculoTempoDeProdutivoBoxConteudoDescricaoPrimeiroValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: -15px;
  height: 25px;
  width: 120px;
  left: -5px;
  background-color: rgb(0, 33, 121);
  z-index: 99
}

.veiculoTempoDeProdutivoBoxConteudoDescricaoSegundo {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position:absolute;
  top: 35px;
  font-weight: bold;
  left: -5px
}

.veiculoTempoDeProdutivoBoxConteudoDescricaoSegundoValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: 55px;  
  height: 25px;
  width: 90px;
  left: -5px;
  background-color: #64813d;
  z-index: 99
}

// Box rodapé
.veiculoTempoDeProdutivoBoxConteudoRodape {
  width: 100%;
  border-color: blue;
  text-align: center;
  left: -30px;
  width: 120%;
  height: 140px;
  position:absolute;
  top: 120px
}

// Conteudo do box 1
.veiculoTempoDeProdutivoBoxConteudoRodapeBox1 {
  float: left;
  width: 34%;
  height: 70%;
  position: relative;
  display: inline-table;
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 33, 121)
}

.veiculoTempoDeProdutivoBoxConteudoRodapeBox1Titulo {
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeProdutivoBoxConteudoRodapeBox1Valor {
  font-size: 23px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeProdutivoBoxConteudoRodapeBox1Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center; 
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 2
.veiculoTempoDeProdutivoBoxConteudoRodapeBox2 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table;
  // "background-color": "red",
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 33, 121)
}

.veiculoTempoDeProdutivoBoxConteudoRodapeBox2Titulo {
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeProdutivoBoxConteudoRodapeBox2Valor {
  font-size: 23px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeProdutivoBoxConteudoRodapeBox2Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;  
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 3
.veiculoTempoDeProdutivoBoxConteudoRodapeBox3 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table
  // "background-color": "green"
}

.veiculoTempoDeProdutivoBoxConteudoRodapeBox3Titulo {
  font-size: 14px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position:absolute;
  color:rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeProdutivoBoxConteudoRodapeBox3Valor {
  font-size: 23px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeProdutivoBoxConteudoRodapeBox3Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}
// Layout do componente Tempo Produtivo //
// Layout do componente Tempo Deslocamento //
.veiculoTempoDeDeslocamentoFundoCirculo {
  border-radius: 50%;
  width: 85%;
  height: 35%;
  margin: 90px 5%;
  position: absolute;
  z-index: 99;
  // background: aquamarine;
  background-color: rgb(255, 255, 255);
}

.veiculoTempoDeDeslocamentoConteudoCirculo {
  align-items: center;
  // background: bisque;
  height: 100%;
  width: 100%;
  margin: 5px 0px 0px 0px;
  position: absolute;
  z-index: 99;
}

.veiculoTempoDeDeslocamentoInfo {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: rgb(255, 255, 255);
  padding: 1rem;
  align-items: center;
  text-align: start;
  background: #a0a0a0;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 150px;
  right: 5px;
  z-index: 99
}

.veiculoTempoDeDeslocamentoInfoButton {
  align-items: center;
  width: 15px;
  height: 15px;
  position: absolute;
  top: -1px;
  right: 13px;
  background-color: #ffffff00;
  border: none;
  z-index: 99;
  color: #ffffff
}

.veiculosTempoDeDeslocamentoFundoTopo {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  padding: 1rem;
  align-items: center;
  text-align: start;
  height: 140px;
  background: rgb(0, 33, 121);
  clip-path: polygon(0 20px, 100% 30%, 100% 100%, 0 100%);
  margin: 0px 0px -40px 0px;
}

.veiculoTempoDeDeslocamentoBoxConteudo {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(2, 6, 102, 0.15);
  border-radius: 8px;
  margin-bottom: 1.5rem;
  height: 550px
}

// Conteudo interno
.veiculoTempoDeDeslocamentoBoxConteudoTitulo {
  font-size: 16px;
  line-height: 16px;
  color: #592f8d;
  align-items: center;
  text-align: center;
  width: 95%;
  height: 25px;
  position: absolute;
  top: 320px;
  z-index: 99
}

// Borda em contorno cortado na metade
.veiculoTempoDeDeslocamentoBoxConteudoPorcentagem {
  width: 80%;
  border: solid;
  border-color: blue;
  border-radius: 0px 50px 50px 0px;
  height: 75px;
  position: absolute;
  top: 390px;
  z-index: 99;
  border-width: 3px 3px 3px 0px;
  left: 45px
}

.veiculoTempoDeDeslocamentoBoxConteudoPorcentagemValor {
  font-size: 22px;
  font-weight: bold;
  font-family: inherit;
  color: #592f8d;
  position: absolute;
  right: 10px;
  top: 18px
}

.veiculoTempoDeDeslocamentoBoxConteudoDescricaoPrimeiro {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position: absolute;
  top: -35px;
  left: -5px
}

.veiculoTempoDeDeslocamentoBoxConteudoDescricaoPrimeiroValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: -15px;
  height: 25px;
  width: 120px;
  left: -5px;
  background-color: rgb(0, 33, 121);
  z-index: 99
}

.veiculoTempoDeDeslocamentoBoxConteudoDescricaoSegundo {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position:absolute;
  top: 35px;
  font-weight: bold;
  left: -5px
}

.veiculoTempoDeDeslocamentoBoxConteudoDescricaoSegundoValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: 55px;  
  height: 25px;
  width: 60px;
  left: -5px;
  background-color: #592f8d;
  z-index: 99
}

// Box rodapé
.veiculoTempoDeDeslocamentoBoxConteudoRodape {
  width: 100%;
  border-color: blue;
  text-align: center;
  left: -30px;
  width: 120%;
  height: 140px;
  position:absolute;
  top: 120px
}

// Conteudo do box 1
.veiculoTempoDeDeslocamentoBoxConteudoRodapeBox1 {
  float: left;
  width: 34%;
  height: 70%;
  position: relative;
  display: inline-table;
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 33, 121)
}

.veiculoTempoDeDeslocamentoBoxConteudoRodapeBox1Titulo {
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeDeslocamentoBoxConteudoRodapeBox1Valor {
  font-size: 23px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeDeslocamentoBoxConteudoRodapeBox1Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center; 
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 2
.veiculoTempoDeDeslocamentoBoxConteudoRodapeBox2 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table;
  // "background-color": "red",
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 33, 121)
}

.veiculoTempoDeDeslocamentoBoxConteudoRodapeBox2Titulo {
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeDeslocamentoBoxConteudoRodapeBox2Valor {
  font-size: 23px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeDeslocamentoBoxConteudoRodapeBox2Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;  
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 3
.veiculoTempoDeDeslocamentoBoxConteudoRodapeBox3 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table
  // "background-color": "green"
}

.veiculoTempoDeDeslocamentoBoxConteudoRodapeBox3Titulo {
  font-size: 14px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position:absolute;
  color:rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeDeslocamentoBoxConteudoRodapeBox3Valor {
  font-size: 23px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoDeDeslocamentoBoxConteudoRodapeBox3Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}
// Layout do componente Tempo Deslocamento //
// Layout do componente Tempo Nao Produtivo //
.veiculoTempoNaoProdutivoFundoCirculo {
  border-radius: 50%;
  width: 85%;
  height: 35%;
  margin: 60px 5%;
  position: absolute;
  z-index: 99;
  // background: aquamarine;
  background-color: rgb(255, 255, 255);
}

.veiculoTempoNaoProdutivoConteudoCirculo {
  align-items: center;
  // background: bisque;
  height: 80%;
  width: 100%;
  margin: 5px 0px 0px 0px;
  position: absolute;
  z-index: 99;
}

.veiculoTempoNaoProdutivoInfo {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: rgb(255, 255, 255);
  padding: 1rem;
  align-items: center;
  text-align: start;
  background: #a0a0a0;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 150px;
  right: 5px;
  z-index: 99
}

.veiculoTempoNaoProdutivoInfoButton {
  align-items: center;
  width: 15px;
  height: 15px;
  position: absolute;
  top: -1px;
  right: 13px;
  background-color: #ffffff00;
  border: none;
  z-index: 99;
  color: #ffffff
}

.veiculosveiculoTempoNaoProdutivoFundoTopo {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  padding: 1rem;
  align-items: center;
  text-align: start;
  height: 110px;
  background: rgb(0, 33, 121);
  clip-path: polygon(0 13px, 100% 30%, 100% 100%, 0 100%);
  margin: 30px 0px -40px 0px;
}

.veiculoTempoNaoProdutivoBoxConteudo {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(2, 6, 102, 0.15);
  border-radius: 8px;
  margin-bottom: 1.5rem;
  height: 500px
}

// Conteudo interno
.veiculoTempoNaoProdutivoBoxConteudoTitulo {
  font-size: 16px;
  line-height: 16px;
  color: #a30003;
  align-items: center;
  text-align: center;
  width: 95%;
  height: 25px;
  position: absolute;
  top: 320px;
  z-index: 99
}

// Borda em contorno cortado na metade
.veiculoTempoNaoProdutivoBoxConteudoPorcentagem {
  width: 80%;
  border: solid;
  border-color: blue;
  border-radius: 0px 50px 50px 0px;
  height: 75px;
  position: absolute;
  top: 350px;
  z-index: 99;
  border-width: 3px 3px 3px 0px;
  left: 45px
}

.veiculoTempoNaoProdutivoBoxConteudoPorcentagemValor {
  font-size: 22px;
  font-weight: bold;
  font-family: inherit;
  color: #a30003;
  position: absolute;
  right: 10px;
  top: 18px
}

.veiculoTempoNaoProdutivoBoxConteudoDescricaoPrimeiro {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position: absolute;
  top: -35px;
  left: -5px
}

.veiculoTempoNaoProdutivoBoxConteudoDescricaoPrimeiroValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: -15px;
  height: 25px;
  width: 120px;
  left: -5px;
  background-color: rgb(0, 33, 121);
  z-index: 99
}

.veiculoTempoNaoProdutivoBoxConteudoDescricaoSegundo {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position:absolute;
  top: 35px;
  font-weight: bold;
  left: -5px
}

.veiculoTempoNaoProdutivoBoxConteudoDescricaoSegundoValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: 55px;  
  height: 25px;
  width: 90px;
  left: -5px;
  background-color: #a30003;
  z-index: 99
}

// Box rodapé
.veiculoTempoNaoProdutivoBoxConteudoRodape {
  width: 100%;
  border-color: blue;
  text-align: center;
  left: -30px;
  width: 120%;
  height: 140px;
  position:absolute;
  top: 120px
}

// Conteudo do box 1
.veiculoTempoNaoProdutivoBoxConteudoRodapeBox1 {
  float: left;
  width: 34%;
  height: 70%;
  position: relative;
  display: inline-table;
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 33, 121)
}

.veiculoTempoNaoProdutivoBoxConteudoRodapeBox1Titulo {
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoNaoProdutivoBoxConteudoRodapeBox1Valor {
  font-size: 23px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoNaoProdutivoBoxConteudoRodapeBox1Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center; 
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 2
.veiculoTempoNaoProdutivoBoxConteudoRodapeBox2 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table;
  // "background-color": "red",
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 33, 121)
}

.veiculoTempoNaoProdutivoBoxConteudoRodapeBox2Titulo {
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoNaoProdutivoBoxConteudoRodapeBox2Valor {
  font-size: 23px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoNaoProdutivoBoxConteudoRodapeBox2Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;  
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 3
.veiculoTempoNaoProdutivoBoxConteudoRodapeBox3 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table
  // "background-color": "green"
}

.veiculoTempoNaoProdutivoBoxConteudoRodapeBox3Titulo {
  font-size: 14px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position:absolute;
  color:rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoNaoProdutivoBoxConteudoRodapeBox3Valor {
  font-size: 23px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.veiculoTempoNaoProdutivoBoxConteudoRodapeBox3Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}
// Layout do componente Tempo Nao Produtivo //

// Layout do componente Tempo de Trabalho //
.tempoDeTrabalhoFundoCirculo {
  border-radius: 50%;
  width: 85%;
  height: 35%;
  margin: 120px 5%;
  position: absolute;
  z-index: 99;
  // background: aquamarine;
  background-color: rgb(255, 255, 255);
}

.tempoDeTrabalhoConteudoCirculo {
  align-items: center;
  // background: bisque;
  height: 80%;
  width: 100%;
  margin: 5px 0px 0px 0px;
  position: absolute;
  z-index: 99;
}

.tempoDeTrabalhoInfo {
  color: rgb(255, 255, 255);
  align-items: center;
  background: #a0a0a0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 150px;
  right: 5px;
  z-index: 99;
  padding: 5px 1px 1px 6px;
  :hover {
    background: #a0a0a0;
    color: black;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 99;
    top: 0px;
    right: 0px;
    padding: 1px 1px 1px 1px;
  }
}

.tempoDeTrabalhoInfoButton {
  align-items: center;
  width: 15px;
  height: 15px;
  position: absolute;
  top: -1px;
  right: 13px;
  background-color: #ffffff00;
  border: none;
  z-index: 99;
  color: #ffffff
}

.tempoDeTrabalhoFundoTopo {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  padding: 1rem;
  align-items: center;
  text-align: start;
  height: 170px;
  background: #1b6eec;
  clip-path: polygon(0 0, 100% 17%, 100% 100%, 0 100%);
  margin: -30px 0px -40px 0px;
}

.tempoDeTrabalhoBoxConteudo {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(2, 6, 102, 0.15);
  border-radius: 8px;
  margin-bottom: 1.5rem;
  height: 500px
}

// Conteudo interno
.tempoDeTrabalhoBoxConteudoTitulo {
  font-size: 11px;
  line-height: 16px;
  color: black;
  align-items: center;
  text-align: center;
  width: 95%;
  height: 25px;
  position: absolute;
  top: 270px;
  z-index: 99
}

// Borda em contorno cortado na metade
.tempoDeTrabalhoBoxConteudoPorcentagem {
  width: 80%;
  border: solid;
  border-color: blue;
  border-radius: 0px 50px 50px 0px;
  height: 75px;
  position: absolute;
  top: 350px;
  z-index: 99;
  border-width: 3px 3px 3px 0px;
  left: 45px
}

.tempoDeTrabalhoBoxConteudoPorcentagemValor {
  font-size: 20px;
  font-weight: bold;
  font-family: inherit;
  color: rgb(27, 110, 236);
  position: absolute;
  right: 10px;
  top: 18px
}

.tempoDeTrabalhoBoxConteudoDescricaoPrimeiro {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position: absolute;
  top: -35px;
  left: -5px
}

.tempoDeTrabalhoBoxConteudoDescricaoPrimeiroValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: -15px;
  height: 25px;
  width: 160px;
  left: -5px;
  background-color: black;
  z-index: 99
}

.tempoDeTrabalhoBoxConteudoDescricaoSegundo {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position:absolute;
  top: 35px;
  font-weight: bold;
  left: -5px
}

.tempoDeTrabalhoBoxConteudoDescricaoSegundoValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: 55px;  
  height: 25px;
  width: 120px;
  left: -5px;
  background-color: rgb(27, 110, 236);
  z-index: 99
}

// Box rodapé
.tempoDeTrabalhoBoxConteudoRodape {
  width: 100%;
  border-color: blue;
  text-align: center;
  left: -30px;
  width: 120%;
  height: 140px;
  position:absolute;
  top: 120px
}

// Conteudo do box 1
.tempoDeTrabalhoBoxConteudoRodapeBox1 {
  float: left;
  width: 34%;
  height: 70%;
  position: relative;
  display: inline-table;
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(27, 110, 236)
}

.tempoDeTrabalhoBoxConteudoRodapeBox1Titulo {
  font-size: 11px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(27, 110, 236);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeTrabalhoBoxConteudoRodapeBox1Valor {
  font-size: 20px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(27, 110, 236);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeTrabalhoBoxConteudoRodapeBox1Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center; 
  font-weight: bold;
  position: absolute;
  color: rgb(27, 110, 236);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 2
.tempoDeTrabalhoBoxConteudoRodapeBox2 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table;
  // "background-color": "red",
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(27, 110, 236)
}

.tempoDeTrabalhoBoxConteudoRodapeBox2Titulo {
  font-size: 11px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(27, 110, 236);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeTrabalhoBoxConteudoRodapeBox2Valor {
  font-size: 20px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(27, 110, 236);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeTrabalhoBoxConteudoRodapeBox2Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;  
  font-weight: bold;
  position: absolute;
  color: rgb(27, 110, 236);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 3
.tempoDeTrabalhoBoxConteudoRodapeBox3 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table
  // "background-color": "green"
}

.tempoDeTrabalhoBoxConteudoRodapeBox3Titulo {
  font-size: 11px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position:absolute;
  color:rgb(27, 110, 236);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeTrabalhoBoxConteudoRodapeBox3Valor {
  font-size: 20px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position: absolute;
  color: rgb(27, 110, 236);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeTrabalhoBoxConteudoRodapeBox3Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(27, 110, 236);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Layout do componente Tempo de Trabalho //
// Layout do componente Tempo Produtivo //
.tempoDeTrabalhoConteudoCirculo {
  align-items: center;
  // background: bisque;
  height: 80%;
  width: 100%;
  margin: 5px 0px 0px 0px;
  position: absolute;
  z-index: 99;
}

.tempoDeTrabalhoInfo {
  color: rgb(255, 255, 255);
  align-items: center;
  background: #a0a0a0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 150px;
  right: 5px;
  z-index: 99;
  padding: 5px 1px 1px 6px;
  :hover {
    background: #a0a0a0;
    color: black;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 99;
    top: 0px;
    right: 0px;
    padding: 1px 1px 1px 1px;
  }
}

.tempoDeTrabalhoInfoButton {
  align-items: center;
  width: 15px;
  height: 15px;
  position: absolute;
  top: -1px;
  right: 13px;
  background-color: #ffffff00;
  border: none;
  z-index: 99;
  color: #ffffff
}

.tempoDeTrabalhoFundoTopo {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  padding: 1rem;
  align-items: center;
  text-align: start;
  height: 170px;
  background: rgb(0, 33, 121);
  clip-path: polygon(0 0, 100% 17%, 100% 100%, 0 100%);
  margin: -30px 0px -40px 0px;
}

.tempoDeTrabalhoBoxConteudo {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(2, 6, 102, 0.15);
  border-radius: 8px;
  margin-bottom: 1.5rem;
  height: 370px
}

// Conteudo interno
.tempoDeTrabalhoBoxConteudoTitulo {
  font-size: 11px;
  line-height: 16px;
  color: black;
  align-items: center;
  text-align: center;
  width: 95%;
  height: 25px;
  position: absolute;
  top: 270px;
  z-index: 99
}

// Borda em contorno cortado na metade
.tempoDeTrabalhoBoxConteudoPorcentagem {
  width: 80%;
  border: solid;
  border-color: blue;
  border-radius: 0px 50px 50px 0px;
  height: 75px;
  position: absolute;
  top: 350px;
  z-index: 99;
  border-width: 3px 3px 3px 0px;
  left: 45px
}

.tempoDeTrabalhoBoxConteudoPorcentagemValor {
  font-size: 20px;
  font-weight: bold;
  font-family: inherit;
  color: rgb(0, 33, 121);
  position: absolute;
  right: 10px;
  top: 18px
}

.tempoDeTrabalhoBoxConteudoDescricaoPrimeiro {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position: absolute;
  top: -35px;
  left: -5px
}

.tempoDeTrabalhoBoxConteudoDescricaoPrimeiroValor {
  // font-family: RADIOLAND;
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: -15px;
  height: 25px;
  width: 160px;
  left: -5px;
  background-color: black;
  z-index: 99
}

.tempoDeTrabalhoBoxConteudoDescricaoSegundo {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position:absolute;
  top: 35px;
  font-weight: bold;
  left: -5px
}

.tempoDeTrabalhoBoxConteudoDescricaoSegundoValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: 55px;  
  height: 25px;
  width: 120px;
  left: -5px;
  background-color: rgb(0, 33, 121);
  z-index: 99
}

// Box rodapé
.tempoDeTrabalhoBoxConteudoRodape {
  width: 100%;
  border-color: blue;
  text-align: center;
  left: -30px;
  width: 120%;
  height: 140px;
  position:absolute;
  top: 120px
}

// Conteudo do box 1
.tempoDeTrabalhoBoxConteudoRodapeBox1 {
  float: left;
  width: 34%;
  height: 70%;
  position: relative;
  display: inline-table;
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 33, 121)
}

.tempoDeTrabalhoBoxConteudoRodapeBox1Titulo {
  font-size: 11px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeTrabalhoBoxConteudoRodapeBox1Valor {
  font-size: 20px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeTrabalhoBoxConteudoRodapeBox1Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center; 
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 2
.tempoDeTrabalhoBoxConteudoRodapeBox2 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table;
  // "background-color": "red",
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 33, 121)
}

.tempoDeTrabalhoBoxConteudoRodapeBox2Titulo {
  font-size: 11px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeTrabalhoBoxConteudoRodapeBox2Valor {
  font-size: 20px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeTrabalhoBoxConteudoRodapeBox2Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;  
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 3
.tempoDeTrabalhoBoxConteudoRodapeBox3 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table
  // "background-color": "green"
}

.tempoDeTrabalhoBoxConteudoRodapeBox3Titulo {
  font-size: 11px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position:absolute;
  color:rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeTrabalhoBoxConteudoRodapeBox3Valor {
  font-size: 20px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeTrabalhoBoxConteudoRodapeBox3Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Layout do componente Tempo de Trabalho //
// Layout do componente Tempo Produtivo //
.tempoDeProdutivoFundoCirculo {
  border-radius: 50%;
  width: 85%;
  height: 45%;
  margin: 90px 5%;
  position: absolute;
  z-index: 99;
  // background: aquamarine;
  background-color: rgb(255, 255, 255);
}

.tempoDeProdutivoConteudoCirculo {
  align-items: center;
  // background: bisque;
  height: 80%;
  width: 100%;
  margin: 5px 0px 0px 0px;
  position: absolute;
  z-index: 99;
}

.tempoDeProdutivoInfo {
  color: rgb(255, 255, 255);
  align-items: center;
  background: #a0a0a0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 150px;
  right: 5px;
  z-index: 99;
  padding: 5px 1px 1px 6px;
  :hover {
    background: #a0a0a0;
    color: black;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 99;
    top: 0px;
    right: 0px;
    padding: 1px 1px 1px 1px;
  }
}

.tempoDeProdutivoInfoButton {
  align-items: center;
  width: 15px;
  height: 15px;
  position: absolute;
  top: -1px;
  right: 13px;
  background-color: #ffffff00;
  border: none;
  z-index: 99;
  color: #ffffff
}

.tempoDeProdutivoFundoTopo {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  padding: 1rem;
  align-items: center;
  text-align: start;
  height: 140px;
  background: rgb(0, 33, 121);
  clip-path: polygon(0 0, 100% 17%, 100% 100%, 0 100%);
  margin: 0px 0px -40px 0px;
}

.tempoDeProdutivoBoxConteudo {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(2, 6, 102, 0.15);
  border-radius: 8px;
  margin-bottom: 1.5rem;
  height: 370px
}

// Conteudo interno
.tempoDeProdutivoBoxConteudoTitulo {
  font-size: 11px;
  line-height: 16px;
  color: #64813d;
  align-items: center;
  text-align: center;
  width: 95%;
  height: 25px;
  position: absolute;
  top: 270px;
  z-index: 99
}

// Borda em contorno cortado na metade
.tempoDeProdutivoBoxConteudoPorcentagem {
  width: 80%;
  border: solid;
  border-color: blue;
  border-radius: 0px 50px 50px 0px;
  height: 75px;
  position: absolute;
  top: 350px;
  z-index: 99;
  border-width: 3px 3px 3px 0px;
  left: 45px
}

.tempoDeProdutivoBoxConteudoPorcentagemValor {
  font-size: 20px;
  font-weight: bold;
  font-family: inherit;
  color: #64813d;
  position: absolute;
  right: 10px;
  top: 18px
}

.tempoDeProdutivoBoxConteudoDescricaoPrimeiro {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position: absolute;
  top: -35px;
  left: -5px
}

.tempoDeProdutivoBoxConteudoDescricaoPrimeiroValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: -15px;
  height: 25px;
  width: 120px;
  left: -5px;
  background-color: rgb(0, 33, 121);
  z-index: 99
}

.tempoDeProdutivoBoxConteudoDescricaoSegundo {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position:absolute;
  top: 35px;
  font-weight: bold;
  left: -5px
}

.tempoDeProdutivoBoxConteudoDescricaoSegundoValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: 55px;  
  height: 25px;
  width: 90px;
  left: -5px;
  background-color: #64813d;
  z-index: 99
}

// Box rodapé
.tempoDeProdutivoBoxConteudoRodape {
  width: 100%;
  border-color: blue;
  text-align: center;
  left: -30px;
  width: 120%;
  height: 140px;
  position:absolute;
  top: 120px
}

// Conteudo do box 1
.tempoDeProdutivoBoxConteudoRodapeBox1 {
  float: left;
  width: 34%;
  height: 70%;
  position: relative;
  display: inline-table;
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 33, 121)
}

.tempoDeProdutivoBoxConteudoRodapeBox1Titulo {
  font-size: 11px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeProdutivoBoxConteudoRodapeBox1Valor {
  font-size: 20px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeProdutivoBoxConteudoRodapeBox1Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center; 
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 2
.tempoDeProdutivoBoxConteudoRodapeBox2 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table;
  // "background-color": "red",
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 33, 121)
}

.tempoDeProdutivoBoxConteudoRodapeBox2Titulo {
  font-size: 11px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeProdutivoBoxConteudoRodapeBox2Valor {
  font-size: 20px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeProdutivoBoxConteudoRodapeBox2Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;  
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 3
.tempoDeProdutivoBoxConteudoRodapeBox3 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table
  // "background-color": "green"
}

.tempoDeProdutivoBoxConteudoRodapeBox3Titulo {
  font-size: 11px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position:absolute;
  color:rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeProdutivoBoxConteudoRodapeBox3Valor {
  font-size: 20px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeProdutivoBoxConteudoRodapeBox3Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}
// Layout do componente Tempo Produtivo //
// Layout do componente Tempo Deslocamento //
.tempoDeDeslocamentoFundoCirculo {
  border-radius: 50%;
  width: 85%;
  height: 45%;
  margin: 90px 5%;
  position: absolute;
  z-index: 99;
  // background: aquamarine;
  background-color: rgb(255, 255, 255);
}

.tempoDeDeslocamentoConteudoCirculo {
  align-items: center;
  // background: bisque;
  height: 80%;
  width: 100%;
  margin: 5px 0px 0px 0px;
  position: absolute;
  z-index: 99;
}

.tempoDeDeslocamentoInfo {
  color: rgb(255, 255, 255);
  align-items: center;
  background: #a0a0a0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 150px;
  right: 5px;
  z-index: 99;
  padding: 5px 1px 1px 6px;
  :hover {
    background: #a0a0a0;
    color: black;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 99;
    top: 0px;
    right: 0px;
    padding: 1px 1px 1px 1px;
  }
}

.tempoDeDeslocamentoInfoButton {
  align-items: center;
  width: 15px;
  height: 15px;
  position: absolute;
  top: -1px;
  right: 13px;
  background-color: #ffffff00;
  border: none;
  z-index: 99;
  color: #ffffff
}

.tempoDeDeslocamentoFundoTopo {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  padding: 1rem;
  align-items: center;
  text-align: start;
  height: 140px;
  background: rgb(0, 33, 121);
  clip-path: polygon(0 20px, 100% 30%, 100% 100%, 0 100%);
  margin: 0px 0px -40px 0px;
}

.tempoDeDeslocamentoBoxConteudo {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(2, 6, 102, 0.15);
  border-radius: 8px;
  margin-bottom: 1.5rem;
  height: 370px
}

// Conteudo interno
.tempoDeDeslocamentoBoxConteudoTitulo {
  font-size: 11px;
  line-height: 16px;
  color: #592f8d;
  align-items: center;
  text-align: center;
  width: 95%;
  height: 25px;
  position: absolute;
  top: 270px;
  z-index: 99
}

// Borda em contorno cortado na metade
.tempoDeDeslocamentoBoxConteudoPorcentagem {
  width: 80%;
  border: solid;
  border-color: blue;
  border-radius: 0px 50px 50px 0px;
  height: 75px;
  position: absolute;
  top: 350px;
  z-index: 99;
  border-width: 3px 3px 3px 0px;
  left: 45px
}

.tempoDeDeslocamentoBoxConteudoPorcentagemValor {
  font-size: 20px;
  font-weight: bold;
  font-family: inherit;
  color: #592f8d;
  position: absolute;
  right: 10px;
  top: 18px
}

.tempoDeDeslocamentoBoxConteudoDescricaoPrimeiro {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position: absolute;
  top: -35px;
  left: -5px
}

.tempoDeDeslocamentoBoxConteudoDescricaoPrimeiroValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: -15px;
  height: 25px;
  width: 120px;
  left: -5px;
  background-color: rgb(0, 33, 121);
  z-index: 99
}

.tempoDeDeslocamentoBoxConteudoDescricaoSegundo {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position:absolute;
  top: 35px;
  font-weight: bold;
  left: -5px
}

.tempoDeDeslocamentoBoxConteudoDescricaoSegundoValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: 55px;  
  height: 25px;
  width: 60px;
  left: -5px;
  background-color: #592f8d;
  z-index: 99
}

// Box rodapé
.tempoDeDeslocamentoBoxConteudoRodape {
  width: 100%;
  border-color: blue;
  text-align: center;
  left: -30px;
  width: 120%;
  height: 140px;
  position:absolute;
  top: 120px
}

// Conteudo do box 1
.tempoDeDeslocamentoBoxConteudoRodapeBox1 {
  float: left;
  width: 34%;
  height: 70%;
  position: relative;
  display: inline-table;
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 33, 121)
}

.tempoDeDeslocamentoBoxConteudoRodapeBox1Titulo {
  font-size: 11px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeDeslocamentoBoxConteudoRodapeBox1Valor {
  font-size: 20px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeDeslocamentoBoxConteudoRodapeBox1Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center; 
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 2
.tempoDeDeslocamentoBoxConteudoRodapeBox2 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table;
  // "background-color": "red",
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 33, 121)
}

.tempoDeDeslocamentoBoxConteudoRodapeBox2Titulo {
  font-size: 11px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeDeslocamentoBoxConteudoRodapeBox2Valor {
  font-size: 20px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeDeslocamentoBoxConteudoRodapeBox2Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;  
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 3
.tempoDeDeslocamentoBoxConteudoRodapeBox3 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table
  // "background-color": "green"
}

.tempoDeDeslocamentoBoxConteudoRodapeBox3Titulo {
  font-size: 11px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position:absolute;
  color:rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeDeslocamentoBoxConteudoRodapeBox3Valor {
  font-size: 20px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoDeDeslocamentoBoxConteudoRodapeBox3Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}
// Layout do componente Tempo Deslocamento //
// Layout do componente Tempo Nao Produtivo //
.tempoNaoProdutivoFundoCirculo {
  border-radius: 50%;
  width: 85%;
  height: 45%;
  margin: 60px 5%;
  position: absolute;
  z-index: 99;
  // background: aquamarine;
  background-color: rgb(255, 255, 255);
}

.tempoNaoProdutivoConteudoCirculo {
  align-items: center;
  // background: bisque;
  height: 80%;
  width: 100%;
  margin: 5px 0px 0px 0px;
  position: absolute;
  z-index: 99;
}

.tempoNaoProdutivoInfo {
  color: rgb(255, 255, 255);
  align-items: center;
  background: #a0a0a0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 150px;
  right: 5px;
  z-index: 99;
  padding: 5px 1px 1px 6px;
  :hover {
    background: #a0a0a0;
    color: black;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 99;
    top: 0px;
    right: 0px;
    padding: 1px 1px 1px 1px;
  }
}

.tempoNaoProdutivoInfoButton {
  align-items: center;
  width: 15px;
  height: 15px;
  position: absolute;
  top: -1px;
  right: 13px;
  background-color: #ffffff00;
  border: none;
  z-index: 99;
  color: #ffffff
}

.tempoNaoProdutivoFundoTopo {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  padding: 1rem;
  align-items: center;
  text-align: start;
  height: 110px;
  background: rgb(0, 33, 121);
  clip-path: polygon(0 13px, 100% 30%, 100% 100%, 0 100%);
  margin: 30px 0px -40px 0px;
}

.tempoNaoProdutivoBoxConteudo {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(2, 6, 102, 0.15);
  border-radius: 8px;
  margin-bottom: 1.5rem;
  height: 370px
}

// Conteudo interno
.tempoNaoProdutivoBoxConteudoTitulo {
  font-size: 11px;
  line-height: 16px;
  color: #a30003;
  align-items: center;
  text-align: center;
  width: 95%;
  height: 25px;
  position: absolute;
  top: 270px;
  z-index: 99
}

// Borda em contorno cortado na metade
.tempoNaoProdutivoBoxConteudoPorcentagem {
  width: 80%;
  border: solid;
  border-color: blue;
  border-radius: 0px 50px 50px 0px;
  height: 75px;
  position: absolute;
  top: 350px;
  z-index: 99;
  border-width: 3px 3px 3px 0px;
  left: 45px
}

.tempoNaoProdutivoBoxConteudoPorcentagemValor {
  font-size: 20px;
  font-weight: bold;
  font-family: inherit;
  color: #a30003;
  position: absolute;
  right: 10px;
  top: 18px
}

.tempoNaoProdutivoBoxConteudoDescricaoPrimeiro {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position: absolute;
  top: -35px;
  left: -5px
}

.tempoNaoProdutivoBoxConteudoDescricaoPrimeiroValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: -15px;
  height: 25px;
  width: 120px;
  left: -5px;
  background-color: rgb(0, 33, 121);
  z-index: 99
}

.tempoNaoProdutivoBoxConteudoDescricaoSegundo {
  font-size: 10px;
  font-family: inherit;
  color: rgb(117, 117, 117);
  position:absolute;
  top: 35px;
  font-weight: bold;
  left: -5px
}

.tempoNaoProdutivoBoxConteudoDescricaoSegundoValor {
  line-height: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: 55px;  
  height: 25px;
  width: 90px;
  left: -5px;
  background-color: #a30003;
  z-index: 99
}

// Box rodapé
.tempoNaoProdutivoBoxConteudoRodape {
  width: 100%;
  border-color: blue;
  text-align: center;
  left: -30px;
  width: 120%;
  height: 140px;
  position:absolute;
  top: 120px
}

// Conteudo do box 1
.tempoNaoProdutivoBoxConteudoRodapeBox1 {
  float: left;
  width: 34%;
  height: 70%;
  position: relative;
  display: inline-table;
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 33, 121)
}

.tempoNaoProdutivoBoxConteudoRodapeBox1Titulo {
  font-size: 11px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoNaoProdutivoBoxConteudoRodapeBox1Valor {
  font-size: 20px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoNaoProdutivoBoxConteudoRodapeBox1Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center; 
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 2
.tempoNaoProdutivoBoxConteudoRodapeBox2 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table;
  // "background-color": "red",
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  border-color: rgb(0, 33, 121)
}

.tempoNaoProdutivoBoxConteudoRodapeBox2Titulo {
  font-size: 11px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoNaoProdutivoBoxConteudoRodapeBox2Valor {
  font-size: 20px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoNaoProdutivoBoxConteudoRodapeBox2Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;  
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

// Conteudo do box 3
.tempoNaoProdutivoBoxConteudoRodapeBox3 {
  float: left;
  width: 33%;
  height: 70%;
  position: relative;
  display: inline-table
  // "background-color": "green"
}

.tempoNaoProdutivoBoxConteudoRodapeBox3Titulo {
  font-size: 11px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position:absolute;
  color:rgb(0, 33, 121);
  top: 20px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoNaoProdutivoBoxConteudoRodapeBox3Valor {
  font-size: 20px;
  line-height: 16px;
  align-items:center;
  text-align:center;
  font-weight:bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 45px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}

.tempoNaoProdutivoBoxConteudoRodapeBox3Descricao {
  font-size: 8px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 33, 121);
  top: 65px;
  width: 100%;
  height: 25px;
  left: 0px;
  z-index: 99
}
// Layout do componente Tempo Nao Produtivo //

